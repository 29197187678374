<template>
  <b-col
    cols="12"
    md="8"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pull-left"
  >
    <label>Projet </label>
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :value="accountProjectFilter"
      :options="accountProjectOptions"
      class="per-page-selector d-inline-block mx-600"
      style="width: 100%"
      :reduce="(val) => val.value"
      @input="(val) => $emit('update:accountProjectFilter', val)"
    />
  </b-col>
</template>

<script>
import { BCol } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BCol, 
    vSelect,
  },
  props: {
    accountProjectFilter: {
      type: [String, null],
      default: null,
    },
    accountProjectOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
